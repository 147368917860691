.container {
  p {
    @apply font-poppins;
    @apply text-base;

    margin: 16px 0;
  }

  ul {
    list-style: disc;
    padding: 0 24px;
  }

  ol {
    counter-reset: list;
    padding: 0 24px;
    list-style-position: inside;

    &.decimal {
      margin-left: 10px;
      li::before {
        content: '(' counter(list) ') ';
        counter-increment: list;
      }
    }
  }
}