.spinner {
  @apply w-6;
  @apply h-6;
  @apply rounded-full;

  border-width: 3px;
  border-color: rgba(255, 255, 255, 0.4);
  border-top-color: white;
  border-left-color: white;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
