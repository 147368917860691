.container-pagination {
  @apply flex;
  @apply flex-row;
  @apply justify-center;
  @apply py-4;
  @apply text-gray;
  @apply space-x-2;
}

.page,
.nav {
  @apply h-sm;
  @apply w-sm;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.page-link {
  font-size: 1rem;
  @apply font-poppins;
}

.active-tab {
  @apply border-blue;
  @apply bg-blue;
  @apply text-white;
  border-radius: 5px;
}

.disabled-link {
  @apply invisible;
}
