.btns-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1280px) {
  .btns-row {
    justify-content: center;

    button {
      flex-basis: 47%;
    }
  }
}

@media (max-width: 767px) {
  .description-container {
    @apply flex-col-reverse;

    h1,
    h3,
    p {
      @apply text-center;
    }
    button {
      @apply mx-auto;
    }
  }
}