
.cardContent {
  @apply relative;
  @apply text-gray;
  @apply text-sm;
  @apply px-5;
  @apply pt-3;
  @apply pb-1;
  @apply mb-2;
  @apply bg-gray-50;
  @apply rounded;
  @apply shadow-sm;
}