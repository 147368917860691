.container {
  &.flat table {
    tr td, tr th {
      @apply border-b;
      @apply border-divider;
    }
  }

  &.flat {
    table {
      tbody tr.separator {
        display: none;
      }
    }
  }

  &.elevated table {
    tbody tr:first-child {
      display: none;
    }

    tbody tr.childrenHeader {
      background: none;
      @apply border-b-2;

      th {
        @apply text-xs;

        &.left {
          text-align: left;
        }
      }

      &:first-child {
        display: table-row;
      }
    }

    tbody tr.wrapper {
      background: none;
    }

    tbody tr.custom {
      @apply border-b;

      background: none;
      &:first-child {
        display: table-row;
      }
    }

    tbody tr.separator {
      @apply bg-transparent;
      @apply h-6;
    }

    tbody tr:not(.custom):not(.separator):not(.wrapper):not(.childrenHeader) {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.45);
      border-radius: 5px;

      td:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      td:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  table {
    width: 100%;
    table-layout: fixed;

    tbody {
      tr:hover {
        @apply transition-all;
        @apply bg-gray-100;
        @apply overflow-hidden;
      }
    }

    tbody tr {
      @apply h-14;
      @apply bg-white;
    }

    tr {
      td, th {
        @apply text-sm;
        @apply text-gray-darkest;
        @apply leading-none;

        @apply font-poppins;
        @apply px-2;

        &:first-child {
          @apply pl-4;
        }

        &:last-child {
          @apply pr-4;
        }
      }

      th {
        @apply text-sm;
        @apply uppercase;

        > div {
          @apply flex;
          @apply items-center;

          height: 3.75em;

          h4 {
            display: -webkit-box;

            line-height: 1.25em;

            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            overflow: hidden;
          }
        }
      }

      td {
        @apply text-gray;
        &:first-child {
          @apply text-gray-darkest;
        }

        span {
          @apply whitespace-nowrap;
          @apply overflow-hidden;
          @apply overflow-ellipsis;
        }
      }
    }
  }
}

