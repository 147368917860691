@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  padding: 8px 0 4px 0;
  width: 100%;

  .inner {
    margin: 0 auto;
    border: 5px solid #ccc;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border-top-color: #197acd;

    animation: spin 0.75s infinite linear;
  }
}