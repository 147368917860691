@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  @apply inset-0;
  @apply absolute;
  @apply z-1;

  background-color: rgba(white, 0.45);
}
