/* Works on Firefox */
.scroller {
  scrollbar-width: thin;
  scrollbar-color: #7c7c7c #bebebe;
}

/* width */
.scroller::-webkit-scrollbar {
  @apply w-xxs;
  @apply h-xs;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  background: #f2f2f2;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 10px;
}

/* Corner */
.scroller::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}

/* Handle horizontal */
.scroller::-webkit-scrollbar-thumb:horizontal {
  @apply w-2;
}

/* width */
.scroller-squared::-webkit-scrollbar {
  @apply w-xxs;
  @apply h-xs;
  @apply overflow-hidden;
  @apply bg-transparent;
}

.scroller-squared::-webkit-scrollbar-track {
  @apply bg-transparent;
  @apply overflow-hidden;
}

.scroller-squared::-webkit-scrollbar-thumb:hover {
  background-color: #b0b0b0;
}

.scroller-squared::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 0;
}
