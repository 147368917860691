@tailwind base;
@tailwind components;
@tailwind utilities;

.backdrop-blur {
    backdrop-filter: blur(5px);
}

@layer base {
    @font-face {
        font-family: 'Jost-SemiBold';
        src: local('Jost-SemiBold'), url(./assets/fonts/Jost/static/Jost-SemiBold.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Jost-Regular';
        src: local('Jost-Regular'), url(./assets/fonts/Jost/Jost-Regular.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Poppins-SemiBold';
        src: local('Poppins-SemiBold'), url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Poppins-Medium';
        src: local('Poppins-Medium'), url(./assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Poppins-Regular';
        src: local('Poppins-Regular'), url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
    }
    @font-face {
        font-family: 'Poppins-Italic';
        src: local('Poppins-Italic'), url(./assets/fonts/Poppins/Poppins-Italic.ttf) format('truetype');
    }

    h1 {
        @apply font-jostSemiBold;
        @apply text-2xl;
    }

    h2 {
        @apply font-jostSemiBold;
        @apply text-lg;
    }

    h3 {
        @apply font-poppinsSemiBold;
        @apply text-lg;
    }

    h4 {
        @apply font-poppinsSemiBold;
        @apply text-sm;
        @apply uppercase;
    }

    h5 {
        @apply font-poppins;
        @apply text-base;
        @apply uppercase;
    }

    h6 {
        @apply font-poppins;
        @apply text-sm;
    }

    p {
        @apply font-poppins;
        @apply text-base;
    }

    p.tag {
        @apply font-poppins;
        @apply text-xxs;
        @apply uppercase;
    }

    a {
        @apply font-poppinsSemiBold;
        cursor: pointer;
    }
}

@layer utilities {
    .underline-gap {
        text-underline-offset: 0.16em;
    }

    .dots-size {
        height: 13px;
        width: 13px;
    }
}

html, body, #root {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaflet-pane, .leaflet-bottom {
    z-index: 0 !important;
}

.clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

input::-ms-reveal {
    /* Hide the hideous ms-edge additional eye */
    display: none;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.marquee {
    animation: marquee 9s linear infinite;
}

input[readonly]:not(.opaque) {
    opacity: 0.5;
}

.w-modal-max {
    width: 80vw;
}

@media (max-width: 1023px) {
    .auto-flex {
        flex-direction: column;
    }
}

@media (min-width: 1024px) {
    .auto-flex {
        flex-direction: row;
    }
}
