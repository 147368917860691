@keyframes spin {
  0% {
    transform: none;
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  border: 4px solid #c8c8c8;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-top-color: #197acd;

  animation: spin 0.75s infinite linear;
}