.mapDetailsSmall div {
  max-width: 550px;
}

.ReactModal__Overlay {
  z-index: 3000;
}

@media (min-width: 800px) {
  .customModal {
    position: absolute;
    inset: 30% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0;
    margin-right: -50%;
    transform: translateX(-50%);
    width: calc(100vw - 120px);
    max-width: 800px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px;
  }
}