.spinner {
  @apply w-full;
  @apply h-full;

  @apply border-blue;

  border-left-color: rgba(0, 0, 0, 0.20);
  border-top-color: rgba(0, 0, 0, 0.20);
  border-bottom-color: rgba(0, 0, 0, 0.20);
  border-width: 4px;

  border-radius: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
