.expand-button {
  :hover {
    .expand-icon {
      fill: #197acf;
    }
  }
}

.add-button {
  .cls-1-check-icon {
    fill: #fff;
  }
  :hover {
    .add-icon-1,
    .cls-1-check-icon {
      fill: #197acf;
    }
  }
}
