.label {
  @apply text-sm;
  @apply font-poppins;
  @apply text-gray;
  @apply absolute;
  @apply transition-all;
  @apply truncate;

  &.nonFocused {
    @apply top-8;
    @apply left-1;
    @apply h-8;
    @apply leading-8;
    @apply pointer-events-none;
    @apply text-gray-400;
  }

  &.focused, &.nonEmpty {
    @apply text-xs;
    @apply font-poppinsMedium;
    @apply top-2;
    @apply left-0;

    &.green {
      @apply text-green-700;
    }

    &.red {
      @apply text-red;
    }

    &.blue {
      @apply text-blue;
    }
  }

  &.nonEmpty {
    @apply text-gray;
  }
}
