.popup-container {
  position: absolute;
  left: 0px;
  top: 30px;
  min-width: 150px;
  max-width: 350px;
  min-height: 50px;
  max-height: 250px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  z-index: 1;
  .arrow {
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    position: absolute;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: -10px;
    left: 20px;
    border-radius: 1px;
    @apply bg-gray-light;
  }
  @apply font-poppins;
  .popup-content {
    max-height: 240px;
    overflow-y: scroll;
    border-radius: 7px;
    @apply p-4;
    @apply bg-gray-light;
    overflow: auto;
  }
  .scroller::-webkit-scrollbar-track {
    border-radius: 7px;
  }
}
