.next-title {
  line-height: 45px;
}

@media (max-width: 767px) {
  .next-gen-container {
    @apply pt-10;

    .next-title {
      @apply w-full;
      @apply text-2xl;
      @apply text-center;
    }

    li .list-text {
      @apply leading-8;
      @apply text-base;
      @apply ml-2;
    }

    li {
      @apply mb-4;
    }

    .sign-up-btn {
      @apply mx-auto;
    }
  }
}
