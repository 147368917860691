.spinner {
  width: 100%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: #ddd #3498db #3498db #ddd;
  border-radius: 8000px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
