.tooltip {
  width: 340px;
  top: 0px;
  left: 40px;

  @media (max-width: 767px) {
    left: 0;
  }
}

.aligned {
  text-align: start;
}
