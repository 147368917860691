/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.fv-input {
  border: 1px solid lightgray;
  border-radius: 3px;
  input {
    height: 48px;
  }
}

.anti-fv-input {
  border: none;
}

.what-is-happening {
  @apply absolute top-4;
  span {
    @apply text-blue uppercase;
  }
}
