.spinner {
  @apply w-5;
  @apply h-4;
  @apply relative;

  &::after {
    @apply w-4;
    @apply h-4;

    position: absolute;
    content: '';

    left: 0;
    top: 0;

    border: 3px solid rgba(#197acf, 0.25);
    border-radius: 0.5rem;
    border-top-color: currentColor;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
