.title {
  line-height: 45px;
}

@media (max-width: 767px) {
  .title {
    @apply leading-8;
    @apply text-center;
  }
}
