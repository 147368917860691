.popup {
  @apply font-poppins;
  @apply overflow-y-auto;
  @apply flex-1;

  &::-webkit-scrollbar {
    @apply w-xxs;
    @apply h-xs;
    @apply overflow-hidden;
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
    @apply overflow-hidden;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0b0b0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bebebe;
  }
}
