.editableBox {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  white-space: normal;
}

.placeholder {
  @apply text-placeholder;
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply pointer-events-none;

  font-family: inherit;
  font-size: inherit;
  padding: inherit;
}

.focused {
  border-color: red;
}

