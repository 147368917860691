.hbspt-form {
  width: 60%;
  margin: 0 auto;
}

.hs-form-iframe {
  height: 1000px !important;
}

wix-iframe[data-src="https://share.hsforms.com/1lA50mT5HQ9iFLWyspTjwsA4rdkd"]
{
  min-height: 700px !important;
}
