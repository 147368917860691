.forgot-password {
  cursor: pointer;
  position: absolute;
}

@media (max-width: 1088px) {
  .menu {
    @apply hidden;
  }
}

.mobile-menu {
  height: calc(100vh - 72px);
  animation: slide-down 0.25s;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
