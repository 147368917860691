.react-calendar__year-view__months__month:hover {
  background-color: #c0c0c0;
  border-radius: 4px;
}

.react-calendar__year-view__months__month {
  min-height: 40px;
  transition-duration: 0.3s;
}

abbr {
  text-decoration: none !important;
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday {
  @apply text-center;
  @apply text-gray-darkest;
  @apply text-xs;
}

.react-calendar button {
  outline: none;
}

.react-calendar__month-view__weekdays {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.react-calendar__navigation {
  @apply mb-4;
  text-align: center;
}

.react-calendar__navigation__label__labelText {
  @apply font-poppinsSemiBold;
  @apply text-gray-darkest;
}

.react-calendar__tile {
  @apply text-xs;
  @apply h-10;
}

.react-calendar__tile:disabled {
  opacity: 0.3;
}
.react-calendar__tile--active {
  @apply bg-blue;
  @apply rounded-md;
  @apply shadow-md;
  @apply text-white;
}

.react-calendar__tile--hasActive {
  @apply bg-blue;
  @apply rounded-md;
  @apply shadow-md;
  @apply text-white;
}

.react-calendar {
  @apply font-poppins;
}
