.loader-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.loader {
  top: 50%;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -5px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid #ccc;
    border-top-color: #197acf;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
