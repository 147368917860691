.map {
  flex-shrink: 0;

  &:global(.leaflet-container) {
    @apply rounded;

    min-height: 540px;
    max-height: 540px;
  }

  :global(.leaflet-top), :global(.leaflet-bottom) {
    z-index: 0;
  }

  :global(.leaflet-div-icon) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
  }
}
