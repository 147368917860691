select,
select::before,
select::after {
  box-sizing: border-box;
}

select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  &:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    @apply border;
    @apply border-blue;
    border-radius: inherit;
  }

  &::-ms-expand {
    display: none;
  }
}

.fv-select {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-areas: 'fv-select';
  min-width: 15ch;
  border-radius: 5px;
  padding: 0.25em 0.25em 0.25em 1em;
  cursor: pointer;

  &:not(.fv-select--multiple) .fv-down-arrow {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  select,
  &::after {
    grid-area: fv-select;
  }
}

select[multiple] {
  padding-right: 0;
  height: 6rem;

  option {
    white-space: normal;
  }
}

.fv-select--disabled {
  cursor: not-allowed;
}

.fv-clear-options {
  position: absolute;
  top: -5px;
  left: 0;
  font-size: 18px;
}
