.spinner {
  @apply w-full;
  @apply h-full;
  @apply rounded-full;
  @apply border-blue;

  border-width: 3px;
  border-style: solid;

  &.thick {
    border-width: 4px;
  }

  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-left-color: rgba(0, 0, 0, 0.25);

  animation: spin linear 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
