.badge {
  position: absolute;
  right: -10px;
  top: -8px;
  background: red;
  border-radius: 8px;
  padding-right: 5px;
  padding-left: 5px;
}

.avatar {
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
}

.profile-modal {
  @apply shadow-md;
  @apply rounded;

  min-width: 228px;
}

.triangle {
  position: relative;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  clip: rect(-6px, 16px, 6px, -2px);

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: white;
    transform: rotate(45deg);
    border-color: rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-left-width: 1px;
    border-top-width: 1px;
  }
}

progress {
  position: relative;
  height: 6px;
}

progress[value]::-webkit-progress-value,
progress[value]::-webkit-progress-bar {
  border-radius: 10px;
  width: 100%;
}

progress[value]::-webkit-progress-value {
  background-color: #197acf;
}

progress[value]::-webkit-progress-bar {
  background-color: #f2f2f2;
}

.profile-name {
  font-size: 18px;
}

.profile-points {
  font-size: 14px;
}

.header-date {
  text-transform: capitalize;
}

.mobile-menu {
  height: calc(100vh - 72px);
  animation: slide-down 0.25s;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// Media Query

@media (min-width: 1960px) {
  .notFoundContainer {
    width: 46%;
    left: 18.5%;
  }
}

@media (max-width: 1960px) {
  .notFoundContainer {
    left: 17.5%;
  }
}

@media (max-width: 1760px) {
  .notFoundContainer {
    left: 16%;
  }
}

@media (max-width: 1560px) {
  .notFoundContainer {
    left: 15.5%;
  }
}

@media (max-width: 1440px) {
  .notFoundContainer {
    width: 43%;
    left: 14.5%;
  }
}

@media (max-width: 1024px) {
  .profile-modal {
    width: 80%;
    max-width: unset;
  }

  .notFoundContainer {
    width: 36%;
    left: 17.81%;
  }
}
@media (max-width: 1150px) {
  .notifications {
    width: 550px;
  }
}

@media (min-width: 1151px) {
  .notifications {
    width: 720px;
  }
}
