.searchSpinner {
  @apply border-blue;
  box-sizing: border-box;

  width: 16px;
  height: 16px;

  animation: spin 1s linear infinite;

  border-width: 2.75px;
  border-radius: 10px;
  border-top-color: rgba(0, 0, 0, 0.15);
  border-left-color: rgba(0, 0, 0, 0.15);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
