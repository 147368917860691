.spinner {
  @apply w-4;
  @apply h-4;

  position: relative;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-radius: 0.5rem;
    border-top-color: currentColor;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
